<template>
        <base-page>
        <titlu-pagina Titlu="Ore angajati" @on_add_clicked="show_dialog()" :AdaugaVisible='true' />
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">
                        
                        <el-col :span='8'>
                            <el-form-item label='Angajat' >
                               <SelectAngajat v-model="Filters.NumeAngajat" @selectie="handleSelectAngajat" :IdAngajat.sync="Filters.IdAngajat" :ExcludePresedinteVicepresedinte="true" :arataTipul="true" :arataInfo="true" />
                            </el-form-item>
                        </el-col> 
                        <el-col :span='4'>
                            <el-form-item label='Activitate' >
                                <el-select class='full-width' v-model='Filters.IdActivitate' filterable @change="selectActivitate">
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option-group
                                    v-for="group in Info.activitati"
                                    :key="group.label"
                                    :label="group.label">
                                        <el-option
                                            v-for="item in group.options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-option-group>
                                </el-select>
                            </el-form-item>
                        </el-col> 

                        <el-col :span='4'>
                            <el-form-item label='Subactivitate' >
                                <el-select class='full-width' v-model='Filters.IdSubactivitate' :disabled="!Info.subactivitati.length > 0">
                                    <el-option label='Toate' value='-1'></el-option>
                                    <el-option v-for='item in Info.subactivitati' :key="item.Id" :label='item.Nume' :value='item.Id'></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col> 
                                 
                        <el-col :md='24' >
                            <el-button @click="reset()" > Reset  </el-button>
                            <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                        </el-col>

                    </el-row>
                </el-form>
                </div>
        </el-card>

        <el-table :data="Results" border>
            <el-table-column prop='Angajat' label='Angajat'></el-table-column>
            <el-table-column prop='Activitate' label='Activitate'></el-table-column>
            <el-table-column prop='Subactivitate' label='Subactivitate'></el-table-column>
            <el-table-column prop='NumarOre' label='Numar ore' width="100"></el-table-column>    
            <el-table-column prop='DataAdaugare' label='Data'>
                <template slot-scope="scope">
                    <div >{{scope.row.DataAdaugare | format-date}}</div>
                </template>      
            </el-table-column>    
            <el-table-column fixed="right" label="Actiuni" width="200px" >
                <template slot-scope="scope" >

                    <el-tooltip content="Modificare">
                        <el-button type="primary" icon="el-icon-edit"  circle @click="show_dialog(scope.row.Id)" />
                        </el-tooltip>

                        <el-tooltip content="Sterge" >
                            <el-button type="danger" icon="el-icon-delete" circle @click="delete_item(scope.row)" />
                    </el-tooltip>

                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
        <Ore-angajati-dialog ref='dlg' @save="refresh_info()" />
    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Ore_angajati_dialog from '@/pages/ore_angajati/Ore-angajati_dialog.vue';
import TitluPagina from '@/widgets/TitluPagina';

export default {
    name: "ore_angajati",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'Ore-angajati-dialog': Ore_angajati_dialog,
        'titlu-pagina': TitluPagina
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info: { angajati:[], activitati:[], subactivitati:[]  },                          
            Filters: {IdAngajat: '-1' , IdActivitate:'-1', IdSubactivitate:'-1', NumeAngajat:''},                
            OrderBy: { },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
        }
    },
    methods: {

        async get_info(){
            var response = await this.post("ore_angajati/get_info" );
            this.Info.angajati      = response.Angajati
            this.Info.activitati    = response.Activitati
            this.refresh_info();
        },

        async refresh_info(){
            var response        = await this.post("ore_angajati/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Results        = response.Results;
            this.PaginationInfo = response.PaginationInfo;
            //
            this.select_menu_item('ore_angajati');
        },
        reset(){
            this.Filters = {IdAngajat: '-1' , IdActivitate:'-1', IdSubactivitate:'-1', NumeAngajat:''};
            this.refresh_info();
        },
        async delete_item( item ){
            var confirm =  await this.$confirm(`Sunteti sigur ?`, 'Warning');
            if( confirm )
            {
                await this.post("ore_angajati/delete_item", { id: item.Id } );
                this.refresh_info();
            }
        },

        show_dialog(id){
            this.$refs.dlg.show_me(id);
        },
        handleSelectAngajat(item) {
            this.Filters.IdAngajat = item.id
        },
        async selectActivitate(id){
            var response            = await this.post('activitati/get_subactivitati_from_id',{id_activitate:id})
            this.Info.subactivitati = response.Subactivitati
        }
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();
    }
};
</script>

<style lang="less" scoped>

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

</style>
