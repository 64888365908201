import { render, staticRenderFns } from "./Ore-angajati_list.vue?vue&type=template&id=66f4d1ae&scoped=true&"
import script from "./Ore-angajati_list.vue?vue&type=script&lang=js&"
export * from "./Ore-angajati_list.vue?vue&type=script&lang=js&"
import style0 from "./Ore-angajati_list.vue?vue&type=style&index=0&id=66f4d1ae&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66f4d1ae",
  null
  
)

export default component.exports