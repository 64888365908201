<template>
    <el-dialog title="Ore angajati" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
                
                <el-col :span='24'>
                    <el-form-item label='Activitate' prop="IdActivitate">
                        <el-select class='full-width' v-model='selectedObject.IdActivitate' filterable @change="selectActivitate">
                            <el-option-group
                            v-for="group in Info.activitati"
                            :key="group.label"
                            :label="group.label">
                                <el-option
                                    v-for="item in group.options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-option-group>
                        </el-select>
                    </el-form-item>
                </el-col> 
                <el-col :span='24'>
                    <el-form-item label='Subactivitate' prop="IdSubactivitate">
                        <el-select class='full-width' v-model='selectedObject.IdSubactivitate' :disabled="!Info.subactivitati.length > 0">
                            <el-option v-for='item in Info.subactivitati' :key="item.Id" :label='item.Nume' :value='item.Id'></el-option>
                        </el-select>
                    </el-form-item>
                </el-col> 
            </el-row>
            <el-row :gutter="15" >
                <el-col :span='8'>
                    <el-form-item label='Angajat' prop="IdAngajat">
                        <SelectAngajat v-model="selectedObject.NumeAngajat" @selectie="handleSelectAngajat" :IdAngajat.sync="selectedObject.IdAngajat" />                       
                    </el-form-item>
                </el-col> 
                <el-col :span='8'>
                    <el-form-item label='Zi' prop="DataAdaugare">
                        <el-date-picker v-model="selectedObject.DataAdaugare" class='full-width' type="date" value-format="yyyy-MM-dd" placeholder="Alege ziua"></el-date-picker>                                  
                    </el-form-item>
                </el-col>
                <el-col :span='8'>
                    <el-form-item label='Numar ore' >
                        <el-input-number class='full-width' v-model='selectedObject.NumarOre' ></el-input-number>
                    </el-form-item>
                </el-col> 

                
                       

            </el-row>
        </el-form>

        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "ore_angajati_dialog",
        extends: BasePage,
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                selectedObjectTemplate: {IdAngajat: '', IdActivitate:'', IdSubactivitate:'', IdProiect:'', NumarOre:1, NumeAngajat:'', DataAdaugare:''},    
                selectedObject:{},              
                Info:{ angajati:[], activitati:[], subactivitati:[] },
                                   
                rules: {
                    IdAngajat:          [ { required: true, message: 'Campul este obligatoriu' } ], 
                    IdActivitate:       [ { required: true, message: 'Campul este obligatoriu' } ], 
                    IdSubactivitate:    [ { required: true, message: 'Campul este obligatoriu' } ], 
                    NumarOre:           [ { required: true, message: 'Campul este obligatoriu' } ],                    
                    DataAdaugare:       [ { required: true, message: 'Campul este obligatoriu' } ],                    
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.selectedObject = JSON.parse(JSON.stringify(this.selectedObjectTemplate));
                this.showPopup      = true;
                if( id == null )
                {
                    this.mode = "add";
                }
                else
                {
                    this.mode           = "edit";
                    var result          = await this.post("ore_angajati/get_info_item_dialog", { id: id } );
                    this.selectedObject = result.Item;
                    this.selectActivitate(this.selectedObject.IdActivitate)
                }
            },
            async get_info(){
                var response            = await this.post("ore_angajati/get_info_for_dialog" );
                this.Info.angajati      = response.Angajati
                this.Info.activitati    = response.Activitati
            },
            save: async function() {
               
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("ore_angajati/save", { mode: this.mode, object: this.selectedObject } );
                        this.showPopup = false;
                        this.$emit("save");
                    }
                });
            },
            handleSelectAngajat(item) {
                this.selectedObject.IdAngajat = item.id
            },
            async selectActivitate(id){
                var response                    = await this.post('activitati/get_subactivitati_from_id',{id_activitate:id})
                this.selectedObject.IdProiect   = response.IdProiect
                this.Info.subactivitati         = response.Subactivitati
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>